.StructureTable {
  &__head {
    display: flex;
    &__item {
      flex: 1 1 0px;
    }
  }
  &__item {
    &__column {
      flex: 1 1 0px;
    }
  }
  a,
  span {
    font-size: 18px;
  }
}
