.Microservices {
  &__list {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(5, 4fr);
  }
}

@media only screen and (max-width: 1500px) {
  .Microservices {
    &__list {
      grid-template-columns: repeat(3, 4fr);
    }
  }
}

@media only screen and (max-width: 1100px) {
  .Microservices {
    &__list {
      grid-template-columns: repeat(2, 4fr);
    }
  }
}
