.DownloadRDXTable {
  &__form {
    &__inputs {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(2, 2fr);
    }
  }
  label {
    font-size: 18px;
  }
}
