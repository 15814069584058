@import "../../scss/variables";

.Header {
  top: 0;
  left: 0;
  z-index: 6;
  position: fixed;
  max-height: 120px;
  background: white;
  .main_navigation {
    &__item {
      transition: $transition1;
      &:hover,
      &:focus,
      &--active {
        color: $mainColor !important;
      }
    }
  }
  &__logo {
    img {
      max-width: 160px;
    }
  }
  &__user {
    &__name {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: -5px;
    }
  }
  &__image {
    img {
      width: 70px;
      height: 70px;
      border-radius: 100%;
    }
  }
  svg {
    &.fa-bars {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 767px) {
  .Header {
    padding-bottom: 1rem;
    border-bottom: 1px solid;
    &__logo {
      img {
        max-width: 130px;
      }
      &.p-4 {
        padding: 1rem !important;
      }
      .main_navigation {
        &__item {
          margin-bottom: 1rem;
          margin-right: 0 !important;
        }
      }
    }
  }
}
